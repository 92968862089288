const jQuery = require("jquery");
$( document ).on('turbolinks:load', function() {
    var sidebarBtn = document.getElementsByClassName('sidebar__mobile--btn')[0];
    var opened = false;
    if (sidebarBtn) {
        sidebarBtn.addEventListener('click', function (event) {
            event.preventDefault();
            var sidebar = document.getElementsByClassName('sidebar')[0];
            var overlay = document.getElementsByClassName('overlay-menu')[0];
            sidebar.classList.toggle('sidebar--open');
            overlay.classList.toggle('overlay-menu--active');
            opened = !opened;
            if (opened) {
                sidebarBtn.innerHTML = '<i class="fas fa-times"></i>';
            } else {
                sidebarBtn.innerHTML = '<i class="fas fa-bars"></i>';
            }
        });
    }

    jQuery('.v-dropdown-toggle').click(function () {
        jQuery(this).next('.dropdown').slideToggle();
        if (jQuery(this).find('i').hasClass('fa-bell')) return;
        if (jQuery(this).find('i').hasClass('fa-chevron-down')) {
            jQuery(this).find('i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        } else {
            jQuery(this).find('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        }
    });

    jQuery(document).click(function (e) {
        var target = e.target;
        if (!jQuery(target).is('.v-dropdown-toggle') && !jQuery(target).parents().is('.v-dropdown-toggle'))
//{ $('.dropdown').hide(); }
        {
            jQuery('.dropdown').slideUp();
        }
    });

    checkActiveMenuItems();
});

const checkActiveMenuItems = () => {
    const els = document.querySelectorAll('#sidebar-wrapper a.list-group-item');
    const currentUrl = `${window.location.origin}${window.location.pathname}`;

    Array.prototype.forEach.call(els, (el, index) => {
        if (index === 0 && currentUrl !== el.href) {
            return;
        }
        if (currentUrl.indexOf(el.href) !== -1) {
            el.classList.add('sidebar-bg-menu-item-active')
        }
    })
}
