$( document ).on('turbolinks:load', function() {
    if (window.location.pathname === "/") {
        loadSizesLogo();
        window.addEventListener('resize', function(){
            loadSizesLogo()
        });
    }
});

function loadSizesLogo() {
    var logo_img = document.getElementsByClassName('js-logo')[0];
    if (!logo_img.dataset && !logo_img.dataset.src && !logo_img.dataset.srcFull) {
        console.log("Error loading in images...");
        return;
    }
    if (window.outerWidth > 1920) {
        if (logo_img.style.backgroundImage !== "url("+logo_img.dataset.srcFull+")") {
            logo_img.style.backgroundImage = "url("+logo_img.dataset.srcFull+")";
        }
    } else {
        if (logo_img.style.backgroundImage !== "url("+logo_img.dataset.src+")") {
            logo_img.style.backgroundImage = "url("+logo_img.dataset.src+")";
        }
    }
}