// Holder of all rows
var receipt_holder = document.getElementsByClassName('form__product-rows');
// Each row
var receipt_rows = document.getElementsByClassName('form__product__row');
// Add btn
var receiptaddBtn = document.getElementsByClassName('receipt__products-add');
// Remove btn
var removeBtns = document.getElementsByClassName('form__product-row-remove');
// Each row count
var rowCounts = document.getElementsByClassName('receipt__count');
// Each row unit_price
var rowPrices = document.getElementsByClassName('receipt__price');
var tempInfoCustomers = document.getElementsByClassName('temp_information_customers');

$(document).on('turbolinks:load', function () {
  var deleteReceiptWithRelationsBtn = document.getElementById('deleteReceiptWithRelations');
  var deleteReceiptWithRelationsCustomerBtn = document.getElementById('deleteReceiptWithRelationsCustomer');
  var startPendingDeleteBtn = document.getElementById('startPendingDeleteBtn');
  var startFortnoxInvoiceCreateBtn = document.getElementById('startFortnoxInvoiceCreate');
  var startResetDeleteBtn = document.getElementById('startResetDeleteBtn');

  if (deleteReceiptWithRelationsCustomerBtn) {
    deleteReceiptWithRelationsCustomerBtn.addEventListener('click', function (e) {
      var r = confirm("Är du säker på att du vill ta bort kvittot och dess relationer(tb, ärenden och kund)?");
      if (r === true) {
      } else {
        e.preventDefault();
      }
    });
  }
  if (deleteReceiptWithRelationsBtn) {
    deleteReceiptWithRelationsBtn.addEventListener('click', function (e) {
      var r = confirm("Är du säker på att du vill ta bort kvittot och dess relationer(tb och ärenden)?");
      if (r === true) {
      } else {
        e.preventDefault();
      }
    });
  }
  if (startPendingDeleteBtn) {
    startPendingDeleteBtn.addEventListener('click', function (e) {
      var r = confirm("Är du säker på att du vill starta borttagning? Denna faktura kommer behöva krediteras.");
      if (r === true) {
      } else {
        e.preventDefault();
      }
    });
  }
  if (startFortnoxInvoiceCreateBtn) {
    startFortnoxInvoiceCreateBtn.addEventListener('click', function (e) {
      var r = confirm("Är du säker på att du vill skapa denna faktura i Fortnox? Detta går endast att göra en gång per kvitto.");
      if (r === true) {
      } else {
        e.preventDefault();
      }
    });
  }
  if (startResetDeleteBtn) {
    startResetDeleteBtn.addEventListener('click', function (e) {
      var r = confirm("Är du säker på att du vill ångra borttagning?");
      if (r === true) {
      } else {
        e.preventDefault();
      }
    });
  }
  if (window.location.pathname === '/admin/receipts_create_from_lead') {
    if ($('#receipt_first_name') && $('#receipt_first_name').attr('value')) {
      setTimeout(() => {
        $('#receipt_first_name').val($('#receipt_first_name').attr('value'))
        $('#receipt_last_name').val($('#receipt_last_name').attr('value'))
        $('#receipt_social_security_number').val($('#receipt_social_security_number').attr('value'))
        $('#receipt_phone_number').val($('#receipt_phone_number').attr('value'))
        $('#receipt_adress').val($('#receipt_adress').attr('value'))
        $('#receipt_postal_code').val($('#receipt_postal_code').attr('value'))
        $('#receipt_city').val($('#receipt_city').attr('value'))
        $('#receipt_email').val($('#receipt_email').attr('value'))
      }, 50)
    }
  }

  if (!$('.system__header').data('disable-edit') && (window.location.pathname === '/admin/receipts_create_from_lead' || window.location.pathname === '/admin/receipts/new' || window.location.pathname === '/admin/receipts' || window.location.pathname.slice(0, 15) === '/admin/receipts' && $('.system__header').data('allowEdit'))) {
    receiptaddBtn[0].addEventListener('click', function (event) {
      receipt_holder = document.getElementsByClassName('form__product-rows');
      event.preventDefault();
      var curIndex = receipt_rows.length === 1 ? 1 : receipt_rows.length;
      $(createProductRow(curIndex)).insertAfter($(receipt_holder[0].lastChild));

      $('.js-example-basic-single-' + curIndex).select2({
        data: tempDataToSelect(),
        templateResult: function (d) {
          return $(d.text);
        },
        templateSelection: function (d) {
          $('#receipt_product_rows_attributes_' + curIndex + '_gross_price').val(d.unit_price);
          $('#receipt_product_rows_attributes_' + curIndex + '_price').val(d.unit_price);
          countRows();
          return $(d.text);
        }
      });

      addEventListeners();
      countRows();
    });
    if (window.location.pathname.slice(0, 15) === '/admin/receipts' && document.getElementsByClassName('system__header')[0].dataset.allowEdit) {
      renderSelectsOnEdit();
      $('.customer__select').select2({
        placeholder: "Select",
        allowClear: true,
        data: tempDataCustomersToSelect(),
        templateResult: function (d) {
          return $(d.text);
        },
        templateSelection: function (d) {
          if (document.querySelector('.customer__select').getAttribute('data-selected-id') != -2) {
            handleCustomerUpdate(d);
          }
          countRows();
          return $(d.text);
        },
      });
      const customerSelectEl = document.querySelector('.customer__select');
      if (customerSelectEl) {
        document.querySelector('.customer__select').setAttribute('data-selected-id', 0);
        if ($('.customer__select').data('selected-id') !== -2 && $('.customer__select').data('selected-id') != null && $('.customer__select').data('selected-id')) {
          $('.customer__select').val($('.customer__select').data('selected-id') !== -2 ? $('.customer__select').data('selected-id') : -1).change();
        }
      }

      var newtimer = setInterval(function () {
        countRows();
      }, 1000)
    } else {
      $('.customer__select').select2({
        placeholder: "Select",
        allowClear: true,
        data: tempDataCustomersToSelect(),
        templateResult: function (d) {
          return $(d.text);
        },
        templateSelection: function (d) {
          handleCustomerUpdate(d);
          countRows();
          return $(d.text);
        },
      });
      $('.js-example-basic-single-0').select2({
        data: tempDataToSelect(),
        templateResult: function (d) {
          return $(d.text);
        },
        templateSelection: function (d) {
          $('#receipt_product_rows_attributes_0_gross_price').val(d.unit_price);
          $('#receipt_product_rows_attributes_0_price').val(d.unit_price * $('#receipt_product_rows_attributes_0_count').val());
          updateRecurringNextInvoice(d);
          countRows();
          return $(d.text);
        }
      });
    }

    var createBtn = document.getElementById('createReceiptBtn');
    var updateBtn = document.getElementById('updateReceiptBtn');
    if (createBtn) {
      createBtn.addEventListener('click', function (e) {
        e.preventDefault();
        if ($('#receipt_first_name').val().length > 1 && $('#receipt_last_name').val().length > 1) {
          var r = confirm("Är du säker på att du vill skapa kvittot och låsa det?");
          if (r === true) {
            document.getElementById('receiptForm').submit();
          }
        } else {
          document.getElementById('receipt_alert').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Du måste fylla i förnamn och efternamn.</strong>' +
              '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
              '<span aria-hidden="true">&times;</span>' +
              '</button></div>';
        }
      });
    }
    if (updateBtn) {
      updateBtn.addEventListener('click', function (e) {
        e.preventDefault();
        if ($('#receipt_first_name').val().length > 1 && $('#receipt_last_name').val().length > 1) {
          var r = confirm("Är du säker på att du vill uppdatera kvittot och låsa det?");
          if (r === true) {
            document.getElementById('receiptForm').submit();
          }
        } else {
          document.getElementById('receipt_alert').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Du måste fylla i förnamn och efternamn.</strong>' +
              '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
              '<span aria-hidden="true">&times;</span>' +
              '</button></div>';
        }
      });
    }

    addEventListeners();
  }
});

function renderSelectsOnEdit() {
  var elems = document.getElementsByClassName('form__product__row');

  $('.js-example-basic-single-0').select2({
    data: tempDataToSelect(),
    templateResult: function (d) {
      return $(d.text);
    },
    templateSelection: function (d) {
      $('#receipt_product_rows_attributes_0_gross_price').val(d.unit_price);
      $('#receipt_product_rows_attributes_0_price').val(d.unit_price * $('#receipt_product_rows_attributes_0_count').val());
      countRows();
      updateRecurringNextInvoice(d)
      return $(d.text);
    }
  }).val($('.js-example-basic-single-0').data('selectedId')).change();

  Array.prototype.forEach.call(elems, function (item) {
    if (!item.querySelectorAll('select')[0].dataset.select2Id) {
      console.log("asd", item.querySelectorAll('select')[0]);
      if (!item.querySelectorAll('select')[0].dataset.rowId && !item.querySelectorAll('select')[0].dataset.selectedId) {
        return
      } else {
        var curIndex = item.querySelectorAll('select')[0].dataset.rowId;
      }
      $('.js-example-basic-single-' + curIndex).select2({
        data: tempDataToSelect(),
        templateResult: function (d) {
          return $(d.text);
        },
        templateSelection: function (d) {
          $('#receipt_product_rows_attributes_' + curIndex + '_gross_price').val(d.unit_price);
          $('#receipt_product_rows_attributes_' + curIndex + '_price').val(d.unit_price);
          countRows();
          updateRecurringNextInvoice(d)
          return $(d.text);
        }
      });
      console.log("testar", item.querySelectorAll('select')[0].dataset.selectedId)
      $('.js-example-basic-single-' + curIndex).val(item.querySelectorAll('select')[0].dataset.selectedId).change()
    }
  });
}

function tempDataToSelect() {
  var items = $.each($('.temp_information').data('temp'), function (index, item) {
    item.text = '<div class="product__select">' + item.name + ' <span>' + item.unit_price + 'kr</span></div>';
  });

  return (items)
}

function tempDataCustomersToSelect() {
  var items = $.each($('.temp_information_customers').data('temp'), function (index, item) {
    item.text = '<div class="product__select">' + item.first_name + ' ' + item.last_name + ' <span>' + item.social_security_number + '</span></div>';
  });

  items.unshift({
    id: -1,
    name: "Ny kund",
    text: "<span>Ny kund</span>",
    short_name: "kund",
    unit_price: "3000.0",
    active: true
  });

  return (items)
}


function addEventListeners() {
  Array.prototype.forEach.call(removeBtns, function (item) {
    //  item.removeEventListener('click');
    item.addEventListener('click', function (e) {
      e.preventDefault();
      countRows();
      removeProductRow(item.dataset.rowId ? item.dataset.rowId : undefined);
    })
  });
  Array.prototype.forEach.call(rowCounts, function (item) {
    item.addEventListener('input', function (e) {
      e.preventDefault();
      countRows();
      $('#receipt_product_rows_attributes_' + item.dataset.rowId + '_price').val($('#receipt_product_rows_attributes_' + item.dataset.rowId + '_gross_price').val() * item.value);
    })
  });
  Array.prototype.forEach.call(rowPrices, function (item) {
    item.addEventListener('input', function (e) {
      e.preventDefault();
      countRows();
      $('#receipt_product_rows_attributes_' + item.dataset.rowId + '_price').val(item.value * $('#receipt_product_rows_attributes_' + item.dataset.rowId + '_count').val());
    })
  });
}


function removeProductRow(index) {
  var curElem = document.getElementsByClassName('form__product-row-' + index);
  var oldId = $('.form__product-row-' + index).find('.row_old_id').val() ? $('.form__product-row-' + index).find('.row_old_id').val() : 0;
  if (oldId !== 0) {
    $('#receipt_removed_ids').val(($('#receipt_removed_ids').val().length > 0 ? $('#receipt_removed_ids').val() + "," : $('#receipt_removed_ids').val()) + oldId);
  }
  curElem[0] ? curElem[0].remove() : renderError("couldnt remove");
  countRows();
}

function createProductRow(index) {
  var row = '<tr class="form__product-row-' + index + ' form__product__row"><th class="" scope="row">' + index + '</th>'
      + '<td><select class="js-example-basic-single-' + index + ' receipt__product" name="receipt[product_rows_attributes][' + index + '][product_id]" id="receipt_product_rows_attributes_' + index + '_product_id"></select></td>'
      + '<td><input type="number" class="form-control receipt__price" name="receipt[product_rows_attributes][' + index + '][gross_price]" id="receipt_product_rows_attributes_' + index + '_gross_price" data-row-id="' + index + '"></td>'
      + '<td><input type="number" class="form-control receipt__count" name="receipt[product_rows_attributes][' + index + '][count]" id="receipt_product_rows_attributes_' + index + '_count" data-row-id="' + index + '" value="1"></td>'
      + '<td><input type="number" class="form-control receipt__total__price" name="receipt[product_rows_attributes][' + index + '][price]" id="receipt_product_rows_attributes_' + index + '_price"></td>'
      + '<td><a href="#" class="form__product-row-remove btn btn-danger" data-turbolinks="false" data-row-id="' + index + '"><i class="fas fa-trash-alt"></i></a></td></tr>';
  return row;
}

function handleCustomerUpdate(customer) {
  if (customer.id !== "-1") {
    $('#receipt_save_customer').attr("disabled", true);
  } else {
    $('#receipt_save_customer').attr("disabled", false);
  }

  $('#receipt_first_name').val(customer.first_name);
  $('#receipt_last_name').val(customer.last_name);
  $('#receipt_social_security_number').val(customer.social_security_number);
  $('#receipt_phone_number').val(customer.telephone);
  $('#receipt_email').val(customer.email);
  $('#receipt_adress').val(customer.adress);
  $('#receipt_postal_code').val(customer.postal_code);
  $('#receipt_city').val(customer.city);
  $('#receipt_adress_extra').val(customer.adress_extra);
}

function countRows() {
  var total = 0;
  var count_total = 0;
  $.each($('.form__product__row'), function (index, item) {
    total = total + ($(item).find('.receipt__price').val() * $(item).find('.receipt__count').val());
    $(item).find('.receipt__total__price').val($(item).find('.receipt__price').val() * $(item).find('.receipt__count').val());
    count_total = parseInt(count_total) + parseInt($(item).find('.receipt__count').val());
  });
  $('#products_total_price').html(total + 'kr');
  $('#products_total_count').html(count_total);
}

function updateRecurringNextInvoice(product) {
  var date = new Date();
  var newDate = new Date(date.setMonth(date.getMonth() + product.amount));
  console.log("newDate", newDate);
  document.getElementById('next-invoice').classList.remove('d-none');
  document.getElementById('next-invoice-inner').innerHTML = newDate.toString();

  if (!product.amount) {
    document.getElementById('next-invoice').classList.add('d-none');
  }
}

function renderError(error) {
  console.log(error)
}
