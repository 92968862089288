import {CountUp} from 'countup.js';

var convertTimeIntervalId = null;

$(document).on('turbolinks:load', function () {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    const total_sales = document.getElementById('me-total-sales');
    const total_regrets = document.getElementById('me-total-regrets');
    const total_bonuses = document.getElementById('me-total-bonuses');
    const average_takt = document.getElementById('me-average-takt');
    const realSalary = document.getElementById('me-real-salary');
    const currentSalary = document.getElementById('me-current-salary');

    if (total_sales && total_sales.dataset.amount) {
        const countUp = new CountUp(total_sales, total_sales.dataset.amount).start();
    }
    if (total_regrets && total_regrets.dataset.amount) {
        const countUp = new CountUp(total_regrets, total_regrets.dataset.amount).start();
    }
    if (total_bonuses && total_bonuses.dataset.amount) {
        const countUp = new CountUp(total_bonuses, total_bonuses.dataset.amount).start();
    }
    if (average_takt && average_takt.dataset.amount) {
        const countUp = new CountUp(average_takt, average_takt.dataset.amount).start();
    }
    if (realSalary && realSalary.dataset.amount) {
        const countUp = new CountUp(realSalary, realSalary.dataset.amount).start();
    }
    if (currentSalary && currentSalary.dataset.amount) {
        const countUp = new CountUp(currentSalary, currentSalary.dataset.amount).start();
    }

    const dashBoardStartWorkingBtn = document.getElementById('dashboard-start-working');
    const dashBoardStopWorkingBtn = document.getElementById('dashboard-stop-working');
    if (dashBoardStartWorkingBtn) {
        dashBoardStartWorkingBtn.addEventListener('click', function() {
            dashBoardStartWorkingBtn.disabled = true;
        })
    }
    if (dashBoardStopWorkingBtn) {
        dashBoardStopWorkingBtn.addEventListener('click', function() {
            dashBoardStopWorkingBtn.disabled = true;
        })
    }

    // var estimatedSalary = document.getElementById('estimated-salary');
    // if (estimatedSalary) {
    //     var countUp = new CountUp(estimatedSalary, Number(estimatedSalary.innerText),
    //         {
    //             suffix: 'kr',
    //             duration: 2
    //         });

    //     window.addEventListener('scroll', function () {
    //         if (window.pageYOffset >= 727) {
    //             if (!estimatedSalary.classList.contains("checked")) {
    //                 estimatedSalary.classList.add("checked")
    //                 countUp.start();
    //             }
    //         }
    //     })
    // }

    $('#datetimepicker11').datetimepicker({
        locale: 'sv',
        viewMode: 'months',
        format: 'MM/YYYY'
    });

    $('#datetimepicker13').datetimepicker({
        locale: 'sv',
        viewMode: 'months',
        format: 'MM/YYYY',
        inline: true,
        defaultDate: $('#q_date').val() ? window.moment($('#q_date').val()) : false
    });

    $('#datetimepicker13').on("change.datetimepicker", ({date, oldDate}) => {
        //Use date and oldDate here
        $('#q_date').val(window.moment(date).format('YYYY/MM'));
    });

    $('#datetimepicker14').datetimepicker({
        locale: 'sv',
        viewMode: 'days',
        format: 'DD/MM/YYYY',
        inline: true,
        defaultDate: $('#q_date').val() ? window.moment($('#q_date').val()) : false
    });

    $('#datetimepicker14').on("change.datetimepicker", ({date, oldDate}) => {
        //Use date and oldDate here
        $('#q_date').val(window.moment(date).format('DD/MM/YYYY'));
    });

    if (window.location.pathname === "/admin") {
        var timer = setTimeout(function () {
            if (window.location.pathname === "/admin") {
                location.href = location.href; // Safari fix
            }
            clearTimeout(timer);
        }, 60 * 1000)
    }

    var sellSaveBtn = document.getElementById('sellSaveBtn');

    if (sellSaveBtn) {
        sellSaveBtn.addEventListener('click', function (e) {
            //e.preventDefault();
            var r = confirm("Är du säker på att du vill markera allt som räddat sälj?");
            if (r === true) {
            } else {
                e.preventDefault();
            }
        });
    }
    var timeInfoEl = document.getElementById('time-info');
    var greetingEl = document.getElementById('greeting-info');

    if (greetingEl) {
        var myDate = new Date();
        var hrs = myDate.getHours();
        var greet;

        if (hrs < 12)
            greet = 'God morgon';
        else if (hrs >= 12 && hrs <= 17)
            greet = 'God eftermiddag';
        else if (hrs >= 17 && hrs <= 24)
            greet = 'God kväll';

        greetingEl.innerHTML = greet;
    }

    if (timeInfoEl) {
        var date_since_timer = timeInfoEl.getAttribute("data-time-now");
        var start_hours = timeInfoEl.getAttribute("data-start-hours");
        var startDateTime = new Date(date_since_timer.replace(/\s/, 'T')); // YYYY (M-1) D H m s (start time and date from DB)

        if (start_hours) {
            startDateTime.setSeconds(startDateTime.getSeconds() - (start_hours * 60 * 60));
        }

        // .replace is there to fix bug in ios safari
        var startStamp = startDateTime.getTime();
        let newDate = new Date();
        let newStamp = newDate.getTime();
        newDate = new Date();
        newStamp = newDate.getTime();
        var diff = Math.round((newStamp - startStamp) / 1000);
        var h = Math.floor(diff / (60 * 60));
        diff = diff - (h * 60 * 60);
        var m = Math.floor(diff / (60));
        diff = diff - (m * 60);
        var s = diff;

        var hoursLabel = h > 1 ? 'timmar' : 'timma';
        var minutesLabel = m > 1 ? 'minuter' : 'minut';

        timeInfoEl.innerHTML = h + " " + hoursLabel + " och " + m + " " + minutesLabel;
    }

    var elsToConvert = document.querySelectorAll('.hours-to-time');
    var onceElsToConvert = document.querySelectorAll('.hours-to-time-once');

    if (onceElsToConvert && onceElsToConvert.length > 0) {
        onceElsToConvert.forEach(function (el) {
            var start_hours = el.getAttribute("data-start-hours");
            var startDateTime = new Date();

            if (start_hours) {
                startDateTime.setSeconds(startDateTime.getSeconds() - (start_hours * 60 * 60));
            }

            // .replace is there to fix bug in ios safari
            var startStamp = startDateTime.getTime();
            let newDate = new Date();
            let newStamp = newDate.getTime();
            newDate = new Date();
            newStamp = newDate.getTime();
            var diff = Math.round((newStamp - startStamp) / 1000);
            var h = Math.floor(diff / (60 * 60));
            diff = diff - (h * 60 * 60);
            var m = Math.floor(diff / (60));
            diff = diff - (m * 60);
            var s = diff;

            var hoursLabel = h > 1 ? 'timmar' : 'timma';
            var minutesLabel = m > 1 ? 'minuter' : 'minut';

            el.innerHTML = h + " " + hoursLabel + " och " + m + " " + minutesLabel;
        })
    }
    if (elsToConvert && elsToConvert.length > 0) {
        convertTimeIntervalId = setInterval(function () {
            elsToConvert.forEach(function (el) {
                var date_since_timer = el.getAttribute("data-timer-at");
                var start_hours = el.getAttribute("data-start-hours");
                var startDateTime = new Date(date_since_timer.replace(/\s/, 'T')); // YYYY (M-1) D H m s (start time and date from DB)

                if (start_hours) {
                    startDateTime.setSeconds(startDateTime.getSeconds() - (start_hours * 60 * 60));
                }

                // .replace is there to fix bug in ios safari
                var startStamp = startDateTime.getTime();
                let newDate = new Date();
                let newStamp = newDate.getTime();
                newDate = new Date();
                newStamp = newDate.getTime();
                var diff = Math.round((newStamp - startStamp) / 1000);
                var h = Math.floor(diff / (60 * 60));
                diff = diff - (h * 60 * 60);
                var m = Math.floor(diff / (60));
                diff = diff - (m * 60);
                var s = diff;

                var hoursLabel = h > 1 ? 'timmar' : 'timma';
                var minutesLabel = m > 1 ? 'minuter' : 'minut';

                el.innerHTML = h + " " + hoursLabel + " och " + m + " " + minutesLabel;
            })
        }, 1000)
    } else {
        if (convertTimeIntervalId) {
            clearInterval(convertTimeIntervalId);
        }
    }
});

// $(document).on('turbolinks:click', function () {
//     console.log("click!")
// })
