// lead_call_up_later_btn
$(document).on('turbolinks:load', function () {
  const callBtn = document.getElementById('call-btn');

  if (!callBtn) return;

  callBtn.addEventListener('click', (e) => {
    console.log("call");
    fetch(`/admin/work_on_call?id=${callBtn.dataset.id}`).then((res) => {
      console.log('res', res);
    })
  })
});
