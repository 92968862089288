// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require serviceworker-companion

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
window.toastr = require("toastr");
const jQuery = require("jquery");
import moment from 'moment';
import '../parts/receipt';
import '../parts/selects';
import '../parts/menu';
import '../parts/start';
import '../parts/other';
import '../parts/btns';
import '../parts/work';

window.moment = moment;

require("tempusdominus-bootstrap-4");

$(function () {
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
  } else {
    if (Notification.permission === "granted") {
      // Let's check whether notification permissions have already been granted
      console.log("Permission to receive notifications has been granted");
      getKeys();
    } else {
      if (Notification.permission !== 'denied') {
        // Otherwise, we need to ask the user for permission
        Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            console.log("Permission to receive notifications has been granted");
            getKeys();
          }
        });
      }
    }
  }
});

const getKeys = () => {
  navigator.serviceWorker.register('/serviceworker.js', {scope: './'})
      .then(function (registration) {
        return registration.pushManager.getSubscription()
            .then(function (subscription) {
              if (subscription) {
                return subscription;
              }
              return registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: vapidPublicKey
              });
            });
      }).then(function (subscription) {
    if (localStorage.getItem('NOTIFICATION_KEY') !== JSON.stringify(subscription.toJSON())) {
      sendKeys(subscription.toJSON())
    }
  });
}

const sendKeys = (subscription) => {
  localStorage.setItem('NOTIFICATION_KEY', JSON.stringify(subscription))
  jQuery.post("/subscribe", JSON.stringify({subscription: subscription, seller_id: window.currentSellerId}));
}

//import("jquery");
//import("bootstrap-select");
//
//$(function(){
//
//    $.fn.selectpicker.Constructor.BootstrapVersion = '4.3';
//
//});
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
