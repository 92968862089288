var curSplitLoc = window.location.pathname.split('/');

$( document ).on('turbolinks:load', function() {
    if (window.location.pathname === '/admin/customer_cases/new') {

    }
    if (window.location.pathname === '/admin/receipt_margins/new' || window.location.pathname === '/admin/receipt_margins/') {
        $('.receipt__select').select2({
            placeholder: "Select",
            allowClear: true,
        });
    }
    if (curSplitLoc.length > 4 && curSplitLoc[2] === "customer_cases" && curSplitLoc[4] === "edit" ||
        window.location.pathname === '/admin/customer_cases/new' ||
        window.location.pathname === '/admin/customer_cases/' ||
        window.location.pathname.indexOf('create_customer_case') !== -1) {

        var allReceiptsElem = document.querySelector('.temp_information_receipts');

        if (allReceiptsElem && allReceiptsElem.dataset.temp) {
            var allReceipts = JSON.parse(allReceiptsElem.dataset.temp)
            console.log(allReceipts)
            $('.customer__select').select2({
                placeholder: "Select",
                allowClear: true,
            });
            $('.seller__select').select2({
                placeholder: "Select",
                allowClear: true,
            });
            $('.receipt__select').select2({
                placeholder: "Select",
                allowClear: true,
            }).change(function(event) {
                var selectedReceipt = allReceipts.filter(function (receipt) {
                    return receipt.id === Number(event.target.value);
                })
                if (selectedReceipt[0]) {
                    $('.seller__select').val(selectedReceipt[0].seller_id).trigger('change');
                }
            });
        } else {
            $('.customer__select').select2({
                placeholder: "Select",
                allowClear: true,
            });
            $('.receipt__select').select2({
                placeholder: "Select",
                allowClear: true,
            });
        }



    }
});